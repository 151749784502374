export const faqData = [
  {
    id: 1,
    q1: "Are you a profitable trader ?",
    ans1: "Yes we are, Last Financial Year we used 24L to generate above 30%. Check our",
    q2: "Trading or investing in stock market is risky?",
    ans2: "Completely yes, it is very risky for traders/investors, who doesn’t have any proper knowledge about it.",
    q3: "For whom this program is great for?",
    ans3: "This is made for everyone, this course is split into 11 modules, where you can learn everything from basics to advanced level.",
  },
  {
    id: 2,
    q1: "Can I be a profitable trader after this program?",
    ans1: "Trading is a long way learning process, we will make sure that you will be a better trader than where you are now.",
    q2: "How will I get support after your course?",
    ans2: "You will be connected to our discord server its free for your lifetime, where you can clear doubts whenever needed.",
    q3: "Still have more questions on your mind?",
    ans3: "We got you, please drop your questions on our ",
  },
];
